import React,{Component} from 'react';
import {getEventDayTime} from './common/helpers';
import Slider, { createSliderWithTooltip  } from 'rc-slider';
import en from './lang/en.json';
import ro from './lang/ro.json';
//import cookie, { Cookies } from "react-cookie";
import Cookies from 'universal-cookie';

// import 'rc-slider/assets/index.css';
const cookie = new Cookies();
//const SliderWithTooltip = createSliderWithTooltip(Slider);

class Multibet extends Component {
    constructor(){
        super();
        this.state={
            multiBetRes:[],
            leagueIds:[],
            totalOddsVal:'',
            stepVal:50,
            sliderVal:50,
            bets:[],
            betSlipErr: '',
            getSelectedBetIds:[],
            lang:'',
            clientBets:[]
        }
      //  console.log(ro)
    }
    calculateTotalOdds(){
       
      
        var totalOdds=1;
        var mainData=this.state.multiBetRes
    mainData.map((league)=>{
        league.events.map((data)=>{
           // console.log(data)
            //console.log("totalodds1===",Number(totalOdds) ," data.odds===", Number(data.odds));
            totalOdds = Number(totalOdds) * Number(data.oddsDecimal);
           // console.log("totalodds2===",totalOdds);
        });
    })
       // console.log("totalodds===",totalOdds);
        if(!isNaN(totalOdds)){
            this.setState({totalOddsVal:totalOdds.toFixed(2)})
           // this.totalOdds = totalOdds.toFixed(2);
        }
      //  console.log("totalodds===",this.totalOdds);
    }
    getEventTime(val){
return getEventDayTime(val,false)
    }
    componentDidMount(){
        this.getData(this.state.sliderVal);
       
    //    setTimeout(()=>{
    //  this.getData(this.state.sliderVal);
    //},5*60*100)
    var element=document.getElementsByClassName('rc-slider-handle')[0]
    var lang=ro
    this.setState({lang:lang})
    //  var childNode=document.createElement('div');
    //  var textnode = document.createTextNode("RON");
    //  childNode.appendChild(textnode)
     //element.appendChild(childNode)
     if(element){
    element.innerHTML= "<div>"+ this.state.sliderVal + "</div>"  +"<div>"+"RON" + "</div>"

        }
        if (window.eventBus) {
            // window.eventBus.$on('betslipRemove', (payload) => {
            //     console.log("remove=", payload);
            // });

            // window.eventBus.$on('betslipCreate', (payload) => {
            //     console.log("add=", payload);
            // });
          
            window.eventBus.$on('betslipCount', tickets => {
                console.log("betslip=", tickets);
                console.log("betslip=tickets", tickets.length);
                var eventsArr=[]
                if(tickets.length>0){
                    tickets.map((ticketData,i)=>{
                        if(ticketData.matchId){
                        eventsArr.push(ticketData.matchId);
                        }
                        
                    })
                    this.activeClientSelection(tickets,eventsArr)
                }
                else{
                    console.log("betslipRemove", tickets.length);
                    this.activeClientSelection(null,[])
                }
            });
        }
    }
leagueReArrange(data){
    var obj={}
    var finalArr=[];
    data.forEach((item)=>{
if(obj.hasOwnProperty(item.competition)){
obj[item.competition].push(item)
var objFinal = []
for(var key in obj){
    objFinal.push({
        competition: key,
        leagueId: obj[key][0].leagueId,
        countryCode: obj[key][0].countryCode,
        countryName:obj[key][0].countryName,
        events: obj[key],
       // oddsActive:false,

    })
}
}else{
obj[item.competition] = [item]
 objFinal = []
for(var key in obj){
    objFinal.push({
        competition: key,
        leagueId: obj[key][0].leagueId,
        countryCode: obj[key][0].countryCode,
        countryName:obj[key][0].countryName,
        events: obj[key],
       // oddsActive:false,
    })
}

}
if(objFinal && objFinal.length>0){
 
finalArr=objFinal
}
})

    return finalArr;
}
replaceMatch(eventIndex,leagueIndex,multiGroupID){
       
        var excludeMatches = [];
        var mainData=this.state.multiBetRes;
        var replaceLeagueIds=this.state.leagueIds
        mainData.map((data)=>{
            data.events.map((event,i)=>{
            excludeMatches.push(event.matchID);
        })
        })                                     
        fetch('https://test.bettorlogic.com/Multibet_opd/SportInsightService.svc/GetReplaceBet?ExcludeMatches='+excludeMatches+'&MultiGroupId='+multiGroupID+'&lang=ro')
        .then(response =>  response.json())
        .then(resData => {
            if(resData.multibetItems && resData.multibetItems!=null ){
                if(replaceLeagueIds.indexOf(resData.multibetItems[0].leagueId)==-1){
                    if( mainData[leagueIndex]['events'].length==1){
                   mainData[leagueIndex]={
                    competition: resData.multibetItems[0].competition,
                    leagueId: resData.multibetItems[0].leagueId,
                    countryCode: resData.multibetItems[0].countryCode,
                    countryName:resData.multibetItems[0].countryName,
                    events: [resData.multibetItems[0]],
                   }
                   // this.betReplace(resData.multibetItems[0]); 
                   replaceLeagueIds.push(resData.multibetItems[0].leagueId)   
                   this.setState({leagueIds:replaceLeagueIds})
                }
                if(mainData[leagueIndex]['events'].length>1){
          //          debugger;
            //        mainData[leagueIndex]['events'].splice( mainData[leagueIndex]['events'][eventIndex],1)
                    mainData.forEach((league,i)=>{
                        if(i==leagueIndex){
                        league.events.forEach((event,eventi)=>{
                           if(eventi==eventIndex){
                                league.events.splice(eventi,1)
                                }
                                })
                                }
                          })
                    mainData.push({
                        competition: resData.multibetItems[0].competition,
                    leagueId: resData.multibetItems[0].leagueId,
                    countryCode: resData.multibetItems[0].countryCode,
                    countryName:resData.multibetItems[0].countryName,
                    events: [resData.multibetItems[0]],
                    })
                }
            }
             
                 if(replaceLeagueIds.indexOf(resData.multibetItems[0].leagueId)!==-1){
                     var index=this.state.leagueIds.indexOf(resData.multibetItems[0].leagueId)
                     mainData[leagueIndex]['events'][eventIndex]=resData.multibetItems[0]
                 }
       
    }
    else{
        mainData[leagueIndex]['events'][eventIndex]['disabled']=true;
    }
            var getAllSelectedBetIds = this.state.getSelectedBetIds;
            var clientBets=this.state.clientBets;
            mainData.map((item, index) => {
                item.events.map((event, eventIndex) => {
                    
                    if (getAllSelectedBetIds.indexOf(event.eventId) !== -1) {
                        //console.log("hello",getAllSelectedBetIds,getAllSelectedBetIds.indexOf(event.matchID),"matchId",event.matchID)
                        event.oddsActive = true;
                    }
                    else{
                        event.oddsActive = false;
                    }
                })
            })

    this.setState({multiBetRes:mainData})
    this.calculateTotalOdds(mainData)
        })

    }
    activeClientSelection(i,arr){
        
       
        var getAllSelectedBetIds =cookie.get('activeBets')?cookie.get('activeBets'):[]
        //if(getAllSelectedBetIds)
    //    if( getAllSelectedBetIds.indexOf(arr)==-1){
    //     getAllSelectedBetIds.push(arr)
    //    }
        this.setState({clientBets:arr})
            let multibetResponse=this.state.multiBetRes;
          
            multibetResponse.map((item,index)=>{
                item.events.map((event,eventIndex)=>{
                    if(arr.indexOf(event.eventId)!==-1){
                        event.oddsActive=true;  
                           if(event.oddsActive){
                                   // selectedBets.push(event);
                                    // if(getAllSelectedBetIds.indexOf(event.eventId)===-1){
                                    // getAllSelectedBetIds.push(event.eventId)
                                    // }
                                    }

                    }else{
                        event.oddsActive=false; 
                        // if(getAllSelectedBetIds.indexOf(event.eventId)!==-1){
                        //     getAllSelectedBetIds.splice(getAllSelectedBetIds.indexOf(event.eventId),1)
                        //     }
                    }
                  
                  
           
                })
    
    })
getAllSelectedBetIds=arr
if(arr.length==0){
    console.log("arr",arr)
    cookie.set('activeBets',[]);
    //this.setState({clientBets:arr})
    this.setState({ getSelectedBetIds : []})
}
else{
    
    cookie.set('activeBets',getAllSelectedBetIds);
    this.setState({ getSelectedBetIds : getAllSelectedBetIds})
}
        this.setState({multiBetRes:multibetResponse});
       
       

    }
activeSelection(i,eventId){
  //  console.log("selection",eventId)
  let bets=cookie.get('activeBets')
        let multibetResponse=this.state.multiBetRes;
        var selectedBets=[];
        multibetResponse.map((item,index)=>{
            item.events.map((event,eventIndex)=>{
                if(eventId===event.eventId){
                    event.oddsActive=!event.oddsActive
                    }
                    if(event.oddsActive){
                        selectedBets.push(event.matchID);
                    }
                    if(eventId ==null || eventId =="null"){
                        console.log("selection",eventId)
                        if(event.oddsActive){
                           event.oddsActive=false;
                           if(selectedBets.length>0){
                               selectedBets=[];
                           }
                        }
                    }
            })

})
    this.setState({multiBetRes:multibetResponse})
   // this.setState({ bets: selectedBets })
    //setTimeout(() => {
    //    this.addToBetSlip();
    //},100)
}
getData(val){
        let totalOdds=val
        var getAllSelectedBetIds = this.state.getSelectedBetIds;
        var activeBets=cookie.get('activeBets')
        if(!activeBets){
            activeBets=getAllSelectedBetIds;
        }
        this.setState({betSlipErr:''})
        fetch('https://test.bettorlogic.com/Multibet_opd/SportInsightService.svc/GetMyMultiBetsJson?Totalodds='+val+'&isMobile=false&lang=ro')
        .then(response =>  response.json())
        .then(resData => {
            let leagueId=[];
            if(resData && resData.multibetItems && resData.multibetItems.length>0){
                
            var multiBetResponse=resData.multibetItems   
        let finalData=this.leagueReArrange(multiBetResponse)
        finalData.forEach((data)=>{
            if(leagueId.indexOf(data.leagueId)==-1){
                leagueId.push(data.leagueId);
            }
        data.events.forEach((event)=>{
         if(activeBets.indexOf(event.eventId)==-1){
        event.oddsActive=false;
        }
        else{
            event.oddsActive=true;
        }
            event.disabled=false;
        })
        })
          // console.log("finalData",finalData)
          // finalData=[];
           this.setState({ multiBetRes: finalData }); 
           this.setState({leagueIds:leagueId});
          // this.setState({getSelectedBetIds:[]})
           this.calculateTotalOdds();
        }
        else{
            this.setState({multiBetRes:[]})
          //  this.setState({getSelectedBetIds:[]})
            this.setState({betSlipErr:'No Matches Available'})
            this.setState({totalOddsVal:''})
        }
        })
    
 }

 addAllToBetSlip() {
     var bets = this.state.bets;
     var mainData = this.state.multiBetRes;
     var notSelectedBets = [];
     var getAllSelectedBetIds = this.state.getSelectedBetIds;
     mainData.map((item, index) => {
         item.events.map((event, eventIndex) => {
             if (!event.oddsActive) {
                 notSelectedBets.push(event);
             }
         })

     })
        var betslipReq = [];
     if (notSelectedBets.length > 0) {
         notSelectedBets.forEach((bet) => {
                betslipReq.push({
                    "marketId":bet.opMarketId,
                    "matchId": bet.eventId,
                   // "odd": bet.oddsDecimal,
                    "type": bet.type,
                    "operatorId": bet.operatorId,
                   // "home":bet.homeTeamName,
                    //"away":bet.awayTeamName      
                })
             getAllSelectedBetIds.push(bet.eventId);
         })
            this.setState({ getSelectedBetIds : getAllSelectedBetIds})
            cookie.set('activeBets',getAllSelectedBetIds)
            this.setState({ bets: [] });
            mainData.map((league, i) => {
                league.events.map((event, index) => {
                    //if (event.oddsActive === false) {
                        event.oddsActive = true;
                    //}
                })
            })
            this.setState({ multiBetRes: mainData })
        } else {
            // let betslipErr="please select bet to add betslip"

        }
        if (betslipReq.length > 0) {
            //fetch('https://dev.punepariu.ro/api/betslip/create', {

            //    method: 'post',
            //    headers: { 'Content-Type': 'application/json' },
            //    body:
            //        JSON.stringify({ "tickets": betslipReq })

            //    ,
            //})
            //    .then((response) => response.json())
            //    .then((responseJson) => {
            //        return responseJson;

            //    })
            //    .catch((error) => {
            //        // console.error(error);
            //    });


        //     const requestOptions = {
        //        method: 'POST',
        //        headers: { 'Content-Type': 'application/json' },
        //        body: JSON.stringify({ tickets: betslipReq })
        //     };
        //    fetch('https://dev.punepariu.ro/api/betslip/create', requestOptions);
        console.log("addAllbetsli",betslipReq)
		   if(window.eventBus){
            console.log("addAllbetsli",betslipReq)
		   window.eventBus.$emit('betslipCreate', betslipReq);
		   }
		   
        }

    }

    addToBetSlip(getBetInfo) {
        var bets=this.state.bets;
        var mainData = this.state.multiBetRes;
        var getOddClass;
        var getAllSelectedBetIds = this.state.getSelectedBetIds;
        mainData.map((league, i) => {
            league.events.map((event, index) => {
                if (event.matchID === getBetInfo.matchID) {
                    getOddClass = event.oddsActive;
                    if (getOddClass) {
                        getAllSelectedBetIds.push(getBetInfo.eventId);
                    } else {
                        if(getAllSelectedBetIds.length>0 && getAllSelectedBetIds.indexOf(getBetInfo.matchID)!==-1){
                            getAllSelectedBetIds.splice(getAllSelectedBetIds.indexOf(getBetInfo.matchID),1)
                        }
                        // for (let i = 0; i < getAllSelectedBetIds.length; i++) {
                        //     if (getAllSelectedBetIds[i] === getBetInfo.matchID) {
                        //         getAllSelectedBetIds.splice(i, 1);
                        //     }
                        // }
                    }
                }
            })
        })

        var betslipReq=[];
        // if(bets.length>0){
        // bets.forEach((bet)=>{
        if (getOddClass) {
            // betslipReq.push({
            //     "marketType": getBetInfo.marketName,
            //     "operatorId": getBetInfo.betRef,
            //     "matchId": getBetInfo.eventId,
            //     "type": getBetInfo.outcome,
            //     "odd": getBetInfo.oddsDecimal
            // })  
            betslipReq.push({
                "marketId":getBetInfo.opMarketId,
                "matchId": getBetInfo.eventId,
               // "odd": getBetInfo.oddsDecimal,
                "type": getBetInfo.type,
                "operatorId": getBetInfo.operatorId,
               // "home":getBetInfo.homeTeamName,
                //"away":getBetInfo.awayTeamName  
            })         
        } else {
            betslipReq.push({
                //"marketType": getBetInfo.marketName,
                //"operatorId": getBetInfo.betRef,
                "matchId": getBetInfo.eventId,
                //"type": getBetInfo.outcome,
                // "odd": getBetInfo.oddsDecimal
            })            
        }
        this.setState({ getSelectedBetIds: getAllSelectedBetIds })
        cookie.set('activeBets',getAllSelectedBetIds)
        // })
        // this.setState({bets:[]});
        mainData.map((league,i)=>{
            league.events.map((event, index) => {
                if (event.matchID === getBetInfo.matchID) {
                    if (event.oddsActive) {
                        // event.oddsActive = false;
                        // const requestOptions = {
                        //    method: 'POST',
                        //    headers: { 'Content-Type': 'application/json' },
                        //    body: JSON.stringify({ tickets: betslipReq })
                        // };
                        // fetch('https://dev.punepariu.ro/api/betslip/create', requestOptions);
                        console.log("betslip",betslipReq)
						if(window.eventBus){
                            console.log("betslip",betslipReq)
						window.eventBus.$emit('betslipCreate', betslipReq);
						}
						
                    } else {
                        // event.oddsActive = true;
                    //     const requestOptions = {
                    //        method: 'POST',
                    //        headers: { 'Content-Type': 'application/json' },
                    //        body: JSON.stringify({ tickets: betslipReq })
                    //    };
                    //    fetch('https://dev.punepariu.ro/api/betslip/remove', requestOptions);
					
					  if(window.eventBus){
                        console.log("betslipRemove",betslipReq)  
                          window.eventBus.$emit('betslipRemove', { tickets : betslipReq }); 
					  }
                    }
            }
        })
        })
        //setTimeout(() => {
        this.setState({ multiBetRes: mainData })
        //},100)
        //}else{
        //  // let betslipErr="please select bet to add betslip"
  
        //}
        //if(betslipReq.length > 0) {
        //    fetch('https://dev.punepariu.ro/api/betslip/create', {

        //        method: 'post',
        //        headers: { 'Content-Type': 'application/json' },
        //        body:
        //            JSON.stringify({ "tickets": betslipReq })

        //        ,
        //    })
        //        .then((response) => response.json())
        //        .then((responseJson) => {
        //            return responseJson;

        //        })
        //        .catch((error) => {
        //            // console.error(error);
        //        });
        //}

    // Added fetch post method to post betslip data
    //if (betslipReq.length > 0) {
    //    const requestOptions = {
    //        method: 'POST',
    //        headers: { 'Content-Type': 'application/json' },
    //        body: JSON.stringify({ tickets: betslipReq })
    //    };
    //    fetch('https://dev.punepariu.ro/api/betslip/create', requestOptions);
    //    // const data = response.json();
    //}

    }
    removeEvent(e){
       this.handleOddsChnage(e)
          
    }
    handleOddsChnage(e){
         //  this.preventDefault()
     var value=e;
     var element=document.getElementsByClassName('rc-slider-handle')[0]
    //  var childNode=document.createElement('div');
    //  var textnode = document.createTextNode("RON");
    //  childNode.appendChild(textnode)
     //element.appendChild(childNode)
     if(element){
    element.innerHTML= "<div>"+ e + "</div>"  +"<div>"+"RON" + "</div>"
}
    
     this.setState({sliderVal:e})
        this.getData(e)
       //var arr=["sr:match:23031263","sr:match:23804307","sr:match:23031263"]
        // var arr=[{"name":"Superbet","matchId":"sr:match:23804307","home":"Valerenga"},{"name":"Superbet","matchId":"sr:match:23117315","home":"Charleroi"}]
    //   setTimeout(() => {
    //     this.activeClientSelection(null,arr)
    //   }, 5000);
       
    }
 totalOddsChange(e,evt){
    this.handleOddsChnage(e)
 
    }
    render(){
        var self=this;
       
        return(
            <div className="panel bet-generator">
            <div className="panel-header">
                <div className="panel-title">
                 {self.state.lang=="en"?   <h3>{self.state.lang.bet} <span className="panel-titlePart">{self.state.lang.generator}</span></h3>:
                  <h3>{self.state.lang.generator} <span className="panel-titlePart">{self.state.lang.bet}</span></h3>}
        <div className="panel-titleInfo">*{self.state.lang.dragtoselect}</div>
                </div>  
                <div className="RON-slider">   
                {window.innerWidth>992?   <Slider ref="sliderVal" min={50} step={50} 
     max={1000} onChange={(e,event)=> self.totalOddsChange(e,event)}  />: 
     <Slider ref="sliderVal" min={50} step={50} max={1000} onAfterChange={(e)=>self.removeEvent(e)} />}
                </div>          
            </div>
            <div className="panel-body">
                
                <div className="panel-content">
                    {this.state.multiBetRes && this.state.multiBetRes.length>0 && this.state.multiBetRes.map((data,i)=>{
                      
                        return (
                    <div className="leagueBox" key={i}>
                        <div className="leagueBox-header">
                            <div className="matchBox-col leagueInfo">
                                <span className={"flag-round-16x16 flag-"+ data.countryCode}></span><span>{data.countryName ? data.countryName:''}{data.countryName? " - ":'' } {data.competition?data.competition:''} </span>
                            </div>                                                      
                        </div>
                        <div className="leagueBox-content">
                        {data.events.map((event,eventIndex)=>{
                            return(
                            <div className="matchBox" key={eventIndex}>
                                <div className="matchBox-matchDetails">
                                    <div className="matchBox-col kickOff-team">
                                        <div className="kickOff">
                        <div>{self.getEventTime(event.matchKickOff).split("/")[0]}</div>
                                            <div>{self.getEventTime(event.matchKickOff).split("/")[1]}</div>
                                        </div>
                                        <div className="teams ">
                                            <div className="teamA truncate">{event.homeTeamName} </div>
                        <div className="teamB truncate">{event.awayTeamName}</div>
                                        </div>
                                    </div>
                                    <div className="matchBox-col">
                                        <div className="tip" dangerouslySetInnerHTML={{__html: event.text}}></div>
                                    </div>
                                        <div className={event.oddsActive ? "matchBox-col market-odds active" : "matchBox-col market-odds"} onClick={() => { self.activeSelection(i, event.eventId); self.addToBetSlip(event)}}>
                        <div className="marketName">{event.outcomeDisplay} </div>
                        <div className="odds">{event.oddsDecimal}</div>
                                    </div>
                                    <div className="matchBox-col match-replace">
                                        <span className={event.disabled? "match-replace-icon disabled" :"match-replace-icon "} onClick={()=>self.replaceMatch(eventIndex,i,event.multiGroupId)}>
                                            <img src={require("./../assets/images/icon-replace.svg")} className="replaceMatchIcon" alt="refresh"  />
                                            <img src={require("./../assets/images/icon-replace-hover.svg")} className="replaceMatchIcon-hover" alt="refresh"  />
                                        </span>                                        
                                    </div>
                                </div>
                                <div className="matchBox-matchDetails-mobile">                                    
                                    <div className="tip" dangerouslySetInnerHTML={{__html: event.text}}>
                                        
                                    </div>
                                </div>
                            </div> 
                            )})}                           
                        </div>
                    </div>
                )})}

                    {this.state.betSlipErr && this.state.multiBetRes.length===0 && <div className="noMatches-msgBox">                     
                        <div className="noMatches-msgBox-content">
                            <div>
                                <img src={require("./../assets/images/no-result-icon.svg")} />
                            </div>
                            <div className="NoMatches-txtContainer">
                            <div className="NoMatches-txt">{self.state.lang.noMatches}</div>
                                <div className="NoMatches-subTxt">{self.state.lang.potentialWin}.</div>
                            </div>                               
                        </div>
                        {/* {this.state.betSlipErr} */}                        
                        </div>
                     }

               </div>

            </div>

             <div className="panel-footer">
                <div className="panel-footer-container">
                    <div className="stake-addAllToBetslip">
                        <div className="totalOdds-container">{self.state.lang.totalOdds}: <span className="total-odds">{ this.state.totalOddsVal ? this.state.totalOddsVal :this.state.sliderVal/10}</span></div>
                        {/* <img src={require("./../assets/images/icon-add-grey.svg")} alt="icon" /> */}
                        <img src={require("./../assets/images/icon-refresh.svg")} alt="refresh" onClick={()=>this.getData(this.state.sliderVal)} />
                       <button className={ this.state.multiBetRes.length>0?"btn btn-addAllToBetslip":"btn btn-addAllToBetslip btnDisable"} onClick={()=>this.addAllToBetSlip()} >{self.state.lang.addAlltoBetslip}</button>
                    </div>                    
                </div>
            </div>
                            
        </div>
        )
    }

}
export default Multibet